<script lang="ts" setup>
import { toRefs } from 'vue'
import MailIcon from '../assets/icons/paper-plane-regular.svg'
import PdfIcon from '../assets/icons/file-pdf-regular.svg'

const props = withDefaults(
  defineProps<{ href: string, title: string, secondary?: boolean, inverse?: boolean }>(),
  {
    secondary: false,
    inverse: false,
  },
)
const { href, secondary, inverse } = toRefs(props)

const isMailtoLink = href.value.startsWith('mailto:')
const isPdfLink = href.value.endsWith('.pdf')

const btnClasses = [secondary.value ? 'btn-secondary' : 'btn-primary']
if (inverse.value) btnClasses.push('inverse')
const btnClass = btnClasses.join('-')
</script>

<template>
  <a
    :href="href"
    :title="title"
    class="btn"
    :class="btnClass"
  >
    <MailIcon v-if="isMailtoLink" class="icon" />
    <PdfIcon v-if="isPdfLink" class="icon" />
    <slot />
  </a>
</template>

<style lang="scss" scoped>
.icon {
  margin-right: 0.5rem;
}

.btn-primary-inverse {
  color: $primary;
}

.btn-secondary-inverse {
  color: $secondary;
}

.btn-primary-inverse,
.btn-secondary-inverse {
  background-color: white;
  border-color: white;

  &:hover,
  &:focus {
    background-color: #eee;
    border-color: #eee;
  }
}

.btn-primary-inverse:hover { color: $primary; }
.btn-secondary-inverse:hover { color: $secondary; }
</style>
